import './App.css';
import CategoryList from './components/CategoryList';
import ButtonRound from './UI/ButtonRound';
import SearchProduct from './components/popup/SearchProduct';
import LocalStorage from './handlers/LocalStorage';
import { CategoryContext } from './context/Contexts';
import { IoIosSearch } from 'react-icons/io';
import { useState, useEffect } from 'react';

function App() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    LocalStorage.initializeCategories();
    const savedCategories = LocalStorage.getCategories();
    if (savedCategories) {
      setCategories(savedCategories);
    }
  }, []);

  const [searchProduct, setSearchProduct] = useState(false);

  const openSearchProduct = () => {
    setSearchProduct(!searchProduct);
  };

  const updateCategories = (category) => {
    const updatedCategories = categories.map((item) => {
      if (item.id === category.id) {
        return category;
      }
      return item;
    });
    setCategories(updatedCategories);
    LocalStorage.setCategories(updatedCategories);
  };

  return (
    <CategoryContext.Provider
      value={{
        categories: categories,
        setCategories: setCategories,
        updateCategories: updateCategories,
      }}
    >
      <>
        <div className="App">
          <CategoryList />
          <div className="searchBtn">
            <ButtonRound icon={<IoIosSearch />} onClick={openSearchProduct} />
          </div>
        </div>
        {searchProduct && <SearchProduct close={openSearchProduct} />}
      </>
    </CategoryContext.Provider>
  );
}

export default App;
