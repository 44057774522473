import React, { useContext, useState } from 'react';
import { CategoryContext } from '../../context/Contexts';
import ButtonRound from '../../UI/ButtonRound';
import { IoIosClose } from 'react-icons/io';

const SearchProduct = (props) => {
  const { categories, updateCategories } = useContext(CategoryContext);
  const { close } = props;
  const [search, setSearch] = useState('');
  const [matchedProducts, setMatchedProducts] = useState([]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearch(inputValue);
    if (inputValue.length >= 3) {
      const filteredProducts = categories.flatMap((category) =>
        category.products
          .filter((product) => product.title.toLowerCase().includes(inputValue))
          .map((product) => ({
            product: product,
            category: category,
          }))
      );
      setMatchedProducts(filteredProducts);
    } else {
      setMatchedProducts([]);
    }
  };

  const handleProductClick = (data) => {
    const { category, product } = data;

    if (!category.products_active.includes(product)) {
      category.products_active.push(product);
    }

    updateCategories(category);
    close();
  };

  return (
    <section className="searchProduct">
      <div className="searchProductCloseBtn" onClick={() => close()}>
        <ButtonRound icon={<IoIosClose />} />
      </div>
      <div className="searchProduct_content">
        <div className="searchProduct_header"></div>
        <div className="searchProduct_body">
          {matchedProducts.length > 0 ? (
            <ul className="searchProduct_outputList">
              {matchedProducts.map((item, index) => (
                <li
                  className="searchProduct_outputItem"
                  key={index}
                  onClick={() => {
                    handleProductClick(item);
                  }}
                >
                  <p className="searchProduct_outputItemCategoryName">
                    {item.category.name}
                  </p>
                  <p className="searchProduct_outputItemProductTitle">
                    {item.product.title}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No matching products</p>
          )}
        </div>
        <div className="searchProduct_footer">
          <input
            name="title"
            type="text"
            onChange={handleInputChange}
            autoFocus
          ></input>
        </div>
      </div>
    </section>
  );
};

export default SearchProduct;
