import React from 'react';

const ButtonRound = (props) => {
  // console.log(props);
  return (
    <div className="roundBtn" onClick={props.onClick}>
      {props.icon}
    </div>
  );
};

export default ButtonRound;
