import React, { useContext } from 'react';
import Category from './Category';
import { CategoryContext } from '../context/Contexts';

const CategoryList = () => {
  const { categories } = useContext(CategoryContext);

  return (
    <div className="categoryList">
      {categories &&
        categories.map((category) => {
          return <Category key={category.id} data={category} />;
        })}
    </div>
  );
};

export default CategoryList;
