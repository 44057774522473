import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

const Product = (props) => {
  const { product, deleteProduct, addActiveProduct } = props;
  return (
    <div className="product">
      <MdOutlineCancel
        className="product_iconDel"
        onClick={() => deleteProduct(product.id)}
      />
      <div className="product_title" onClick={() => addActiveProduct(product)}>
        {product.title}
      </div>
    </div>
  );
};

export default Product;
