import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ButtonRound from '../../UI/ButtonRound';
import { IoIosClose, IoIosCheckmark } from 'react-icons/io';

const CreateNewProduct = (props) => {
  const { name, addProduct, openNewProductPopup } = props;

  const [product, setProduct] = useState({});

  const handleInputChange = (e) => {
    setProduct(() => ({
      ...product,
      [e.target.name]: e.target.value,
    }));
  };

  const handleProductSubmit = (e) => {
    e.preventDefault();
    const newProduct = { ...product, id: uuidv4() };
    if (product.title) {
      addProduct(newProduct);
    }
    openNewProductPopup();
    // setProduct({});
  };

  return (
    <section className="createNewProduct">
      <div className="createNewProductCloseBtn">
        <ButtonRound
          icon={<IoIosClose />}
          onClick={() => openNewProductPopup()}
        />
      </div>
      <form className="createNewProduct_form">
        <p>{name}</p>
        <input
          name="title"
          type="text"
          placeholder="aggiungi il nome"
          onChange={handleInputChange}
          autoFocus
        ></input>
        <ButtonRound icon={<IoIosCheckmark />} onClick={handleProductSubmit} />
      </form>
    </section>
  );
};

export default CreateNewProduct;
