import React, { useContext, useState } from 'react';

import { CategoryContext } from '../context/Contexts';

import { IoIosArrowDown, IoMdAdd, IoIosCart } from 'react-icons/io';
import CreateNewProduct from './popup/CreateNewProduct';
import Product from './Product';

const Category = (props) => {
  const { data } = props;
  const { id } = data;
  const { categories, updateCategories } = useContext(CategoryContext);

  const [category, setCategory] = useState(
    categories.find((item) => id === item.id)
  );

  const [categoryListOpen, setCategoryListOpen] = useState(false);
  const [toggleNewProductPopup, setToggleNewProductPopup] = useState(false);
  const [activeProducts, setActiveProducts] = useState(
    category.products_active
  );

  const toggleProductList = () => {
    const a = document.querySelectorAll('.category_productListBtn-active');
    console.log(a);
    if (a.length > 0) {
      a.forEach((item) => {
        item.classList.replace(
          'category_productListBtn-active',
          'category_productListBtn'
        );
      });
    }

    setCategoryListOpen(!categoryListOpen);
  };

  const openNewProductPopup = () => {
    setToggleNewProductPopup(!toggleNewProductPopup);
    setCategoryListOpen(false);
  };

  const addProduct = (product) => {
    if (!category.products.some((item) => item.title === product.title)) {
      const updatedProducts = [...category.products, product];
      setCategory((prevProducts) => ({
        ...prevProducts,
        products: updatedProducts,
      }));
      updateCategories({ ...category, products: updatedProducts });
    } else {
      return;
    }
  };

  const deleteProduct = (id) => {
    const updatedProducts = category.products.filter(
      (product) => product.id !== id
    );
    setCategory((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
    updateCategories({ ...category, products: updatedProducts });
  };

  const addActiveProduct = (product) => {
    if (!activeProducts.some((item) => item.id === product.id)) {
      const activeList = [...activeProducts, product];
      setActiveProducts(activeList);
      setCategoryListOpen(!categoryListOpen);
      console.log(product);
      updateCategories({ ...category, products_active: activeList });
    }
  };

  const delActiveProduct = (product) => {
    let updatedActiveList = activeProducts.filter(
      (item) => item.id !== product.id
    );
    setActiveProducts(updatedActiveList);
    updateCategories({ ...category, products_active: updatedActiveList });
  };

  return (
    <section className="category">
      <div className="category_header">
        <div className="category_title" onClick={toggleProductList}>
          <span>{category.name}</span>
          <button
            className={
              categoryListOpen
                ? 'category_productListBtn-active'
                : 'category_productListBtn'
            }
          >
            <IoIosArrowDown />
          </button>
        </div>
        <button
          className={
            categoryListOpen
              ? 'category_addProductBtn active'
              : 'category_addProductBtn'
          }
          onClick={openNewProductPopup}
        >
          <IoMdAdd />
        </button>
      </div>

      <ul className="activeList">
        {activeProducts &&
          activeProducts.map((item) => {
            return (
              <li className="activeItem" key={item.id}>
                <span onClick={() => delActiveProduct(item)}>{item.title}</span>
                {<IoIosCart />}
              </li>
            );
          })}
      </ul>

      <div className="category_body">
        <ul
          className={categoryListOpen ? 'products_list-open' : 'products_list'}
        >
          {category.products &&
            category.products
              .slice() // Создаем копию массива, чтобы не изменять оригинал
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((product) => {
                return (
                  <li key={product.id}>
                    <Product
                      product={product}
                      deleteProduct={deleteProduct}
                      addActiveProduct={addActiveProduct}
                    />
                  </li>
                );
              })}
        </ul>
      </div>

      {toggleNewProductPopup && (
        <CreateNewProduct
          addProduct={addProduct}
          name={category.name}
          openNewProductPopup={openNewProductPopup}
        />
      )}
    </section>
  );
};

export default Category;
