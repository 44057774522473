import { v4 as uuidv4 } from 'uuid';

const categories = [
  'latte & formaggi',
  'carni & salumi',
  'pane & pasta',
  'verdure & ortaggi',
  'frutta',
  'aqua & bevande',
  'dolci',
  'altro',
];

const categoryList = categories.map((category) => {
  return {
    id: uuidv4(),
    name: category,
    products: [],
    products_active: [],
  };
});

const LocalStorage = {
  initializeCategories() {
    const storedCategories = localStorage.getItem('SpesApp');
    if (!storedCategories) {
      localStorage.setItem('SpesApp', JSON.stringify(categoryList));
    }
  },
  getCategories() {
    return JSON.parse(localStorage.getItem('SpesApp'));
  },
  setCategories(newCategories) {
    localStorage.setItem('SpesApp', JSON.stringify(newCategories));
  },
};

export default LocalStorage;
